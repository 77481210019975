import * as React from 'react'
import Drawer from '@mui/material/Drawer'
import styles from './GenericAppBarV2.module.css'
import { useNavigate } from 'react-router-dom'

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window
  data?: {
    buttonText: string
    clientLogo?: {
      url: string
      alt: string
      width: string
    }
    menuItems: [
      {
        item: string
        subitems: [string]
      },
    ]
  }
}

export default function GenericAppBarV2(props: Props) {
  const { window, data } = props
  const buttonText = data?.buttonText || ''
  const clientLogo = data?.clientLogo || { url: '', alt: '', width: '' }
  const menuItems = data?.menuItems || []
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const navigate = useNavigate()

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }
  const handleMenuItemClick = (item: string) => {
    console.log('clicked', item)
    const id = item.toLowerCase().replace(' ', '-')
    const section = document.getElementById(id)
    if (section) {
      section.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    }
  }

  const container =
    window !== undefined ? () => window().document.body : undefined

  const handleCTAClicked = (): void => {
    navigate('form')
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.logos}>
          <a href="https://www.mentem.co">
            <img src="/applogo.svg" className={styles.logo} alt="mentem logo" />
          </a>
          <div className={styles.logoRla}>
            <img
              src={clientLogo?.url}
              width={clientLogo?.width}
              alt={clientLogo?.alt}
            />
          </div>
        </div>
        <div className={styles.desktopitems}>
          {menuItems.map((mi) => (
            <div key={mi.item} className={styles.menuItemContainer}>
              <button
                className={styles.desktopitem}
                onClick={() => handleMenuItemClick(mi.item)}
              >
                {mi.item}
              </button>
              {/* Render the submenu (visible on hover via CSS) */}
              {mi.subitems && (
                <div className={styles.submenu}>
                  {mi.subitems.map((subitem) => (
                    <button
                      key={subitem}
                      className={styles.submenuItem}
                      onClick={() => handleMenuItemClick(subitem)}
                    >
                      {subitem}
                    </button>
                  ))}
                </div>
              )}
            </div>
          ))}
          {buttonText && (
            <button className={styles.cta} onClick={handleCTAClicked}>
              {buttonText}
            </button>
          )}

          {/* <div className={styles.submenuBackground} /> */}
        </div>

        <button className={styles.menubutton} onClick={handleDrawerToggle}>
          <img src="/menu.png" alt="menu" />
        </button>
      </div>

      {/* mobile menu */}
      <nav>
        <Drawer
          anchor="top"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            transform: 'translateY(90px)',

            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: '100%',
            },
          }}
        >
          <div onClick={handleDrawerToggle} className={styles.mobileitems}>
            {menuItems.map((mi) => (
              <div className={styles.mobileMenuItemContainer} key={mi.item}>
                <button
                  key={mi.item}
                  className={styles.mobileitem}
                  onClick={() => handleMenuItemClick(mi.item)}
                >
                  {mi.item}
                </button>
                {/* Render the submenu (visible on hover via CSS) */}
                {mi.subitems && (
                  <div className={styles.mobileSubmenu}>
                    {mi.subitems.map((subitem) => (
                      <button
                        key={subitem}
                        className={styles.mobileSubmenuItem}
                        onClick={() => handleMenuItemClick(subitem)}
                      >
                        {subitem}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </Drawer>
      </nav>
    </div>
  )
}
